//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
  contactsStore,
  sectionsStore,
  uiSettingsStore,
} from '@/store'

export default {
  name: 'TaskDeskColumnHeader',

  components: {
    BaseEntityAvatar: () => import('@/components/UI/BaseEntityAvatar.vue'),
    HeaderMenu: () => import('./HeaderMenu'),
    IconEllipsis: () => import('@/components/UI/icons/IconEllipsis'),
    UserInfoWindow: () => import('@/components/Chat/Instance/DOM/Components/UserInfoPopup/UserInfoWindow'),
  },

  props: {
    columnKey: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    numTasks: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      'entity',
    ]),
    taskDeskCurrentGroupsOrder () {
      return uiSettingsStore.getters.taskDeskCurrentGroupsOrder
    },
    headerInfoEnabled () {
      const supportedKeys = [
        'assignee',
        'owner',
      ]
      return this.uid && supportedKeys.includes(this.columnKey)
    },
    columnTitle () {
      if (this.columnKey === 'section') {
        return sectionsStore.getters.section(this.uid, 'task')?.name ?? this.title
      }

      if (this.headerInfoEnabled) {
        return contactsStore.getters.contact(this.uid)?.displayName ?? this.title
      }

      if (this.columnKey === 'tag') {
        return `#${this.title}`
      }

      return this.title
    },
    isAlreadyOrdered () {
      return this.taskDeskCurrentGroupsOrder.includes(this.uid)
    },
    jids () {
      return (this.tasks || []).map(entry => entry.jid)
    },
    computedEntity () {
      if (!this.uid) return null
      return this.entity(this.uid)
    },
  },
}
